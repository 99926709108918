.custom-carousel-control {
    width: 5%;
}

.outer-sect{
    padding-left: 30px;
    padding-right: 30px;
}

 .item-tour {
    padding: 5px;
    padding-top: 0px ;
    padding-bottom:  0px ;
  
} 

.list_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.post_images img {
    width: 100%;
    height: auto;
    display: block;
}
.wrapper_content {
    padding: 0 0 10px 0;
}
.description {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
   margin-top: -6px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}
.col-lg-6 {
    width: 50% !important;
}
.enquire {
    float: left;
    width: 100%;
    position: relative;
   
}
.enquire a {
    background: #e5b815;
    padding: 10px 72px;
    color: #ffffff;
    font-weight: 700;
    display: block;
    text-transform: uppercase;
    text-decoration: none;
}
.enquire a:hover {
    background-color: #e5b815;
    color: #fff; 
    text-decoration: underline;
}
.wrapper-price {
    padding: 12px;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    text-align: center;
    justify-content: center;
    background: #e5e5e5;
    font-size: 1.3rem;
    /* margin-top: -5px; */
    margin: 0 !important;
    border-radius: 0px 0px 10px 10px;
}
.price {
    font-family: 'Philosopher', sans-serif !important;
    font-size: 20px;
    color: #010102;
}
.post_date {
    color: #323232 !important;
    font-size: 12px !important;
    font-family: 'Oxygen', sans-serif !important;
    font-weight: 600;
}

.description .icon {
    display: block;
    font-family: 'Oxygen', sans-serif !important;
    line-height: 15px;
}

.description .icon img {
    vertical-align: middle;
    border-style: none;
    object-fit: cover;
    width: auto !important;
 
    
    
}

.description .no-of-days {
   
    /* margin-top: 5px; */
    display: block;
    font-family: 'Oxygen', sans-serif !important;
    line-height: 15px;
}

.description .hotel {
    color: #4a4a4a;
    font-size: 10px;
    font-family: 'Oxygen', sans-serif !important;
    margin-top: 0px;
    margin-bottom: 0px;
    display: block;
}


span.icon, span.no-of-days {
    display: block;
    font-family: 'Oxygen', sans-serif !important;
    line-height: 15px;
}

.madina {
    font-weight: 700;
    font-size: 13px;
    color: #4a4a4a;
    font-family: 'Roboto', sans-serif;
    padding: 15px 5px;
    border-left: 1px solid #d6d6d6;
    background: white;
}

.content-item-inner {
        text-align: center;
        padding-bottom: 6px;
        background: white;
}
a {
    color: #337ab7;
    text-decoration: none;
}


.post_title h4 {
    text-overflow: ellipsis;
    font-size: 20px; 
    background: black;
    padding: 11px 6px;
    margin-top: -10px;
}

.post_images {
    position: relative;
    margin-bottom: 10px;
    overflow: hidden;
}
.post_images img {
    width: 100%;  
    border-top-right-radius: 2%;
    border-top-left-radius: 2%;
}
.post_title {
    margin-bottom: 0px !important;
    
}
.stars{
    position: relative;
    top: -5px;
}
.stars img{
 width: auto !important;
}


.makkah {
    font-size: 13px;
    font-weight: 700;
    padding: 15px 5px;
    border-left: 0 !important;
    background: white;
}
.post_title a {
    color: #fff;
    text-decoration: none;
}

.post_title a:hover {
    color: #f0f0f0; 
}
span.post_date {
    vertical-align: bottom;
    /* border-left: 1px solid #808080; */
    padding-left: 8px;
}

/* .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -23px;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-top: 26px !important;
    margin-bottom: -27px !important;
    margin-left: 15%;
} */

.carousel-indicators {
    position: absolute;
    right: 0;
    
    bottom: 3px;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0px ;
    margin-right: 15%;
    margin-top: 26px !important;
    margin-bottom: -27px !important;
    margin-left: 15%;
    color: #337ab7;
}
.carousel-indicators .active {
    background-color: #e5b815;
  
}


.product-carousel .carousel-item .d-flex {
    display: flex;
    flex-wrap: nowrap; /* Ensure items stay in a single row */
    overflow: hidden; /* Hide overflow to ensure only one item is visible at a time */
}

.product-carousel .carousel-item .d-flex .item-tour {
    flex: 1 0 100%; /* 1 item per row for small screens */
    box-sizing: border-box; /* Include padding in width calculation */
}

/* Adjust for large screens (desktops) */
@media (min-width: 1200px) {
    .product-carousel .carousel-item .d-flex .item-tour {
        flex: 1 0 33.33%; /* 3 items per row */
    }
}

/* Adjust for medium screens (tablets) */
@media (max-width: 1199px) and (min-width: 768px) {
    .product-carousel .carousel-item .d-flex .item-tour {
        flex: 1 0 50%; /* 2 items per row */
    }
}

/* Adjust for small screens (mobile) */
@media (max-width: 767px) {
    .product-carousel .carousel-item .d-flex {
        flex-wrap: nowrap; /* Ensure items stay in a single row */
    }

    .product-carousel .carousel-item .d-flex .item-tour {
        flex: 1 0 100%; /* 1 item per row */
    }
}

.custom-carousel-control {
    width: 5%;
    z-index: 1; /* Ensure controls are above carousel items */
}

.prev-control {
    left: 0;
}

.next-control {
    right: 0;
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 3px;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-top: 26px !important;
    margin-bottom: -27px !important;
    margin-left: 15%;
    color: #337ab7;
}

.carousel-indicators .active {
    background-color: #e5b815;
    
}
/* .carousel-control-prev,
.carousel-control-next {
    display: none;
} */