/* * {
    margin: 0;
    outline: none;
    padding: 0;
} */
/* body {
    background: #fff;
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    line-height: 22px;
} */
.umh-city-sec {
    padding: 30px 0;
    background: #e8e8e8;
    margin-top: 70px;
}
.umh-heading {
    display: block;
    text-align: center;
    margin: 0;
    padding: 0;
}
/* h2 {
    display: block;
} */
/* p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
} */
.umh-heading h2 {
    font-size: 30px;
    line-height: 38px;
    margin: 0 0 10px 0;
    padding: 0;
    font-weight: 400;
    color: #000;
}
.umh-heading p {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 20px 0;
    padding: 0;
    font-weight: normal;
    color: #000;
}
ul.umh-city-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
ul.umh-city-list li {
    list-style: none;
    padding: 0;
    width: 12%;
    margin: 0 0 15px 0;
}
ul.umh-city-list a {
    display: block;
    text-decoration: none !important;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    padding: 15px 0 10px 0;
}
ul.umh-city-list a figure {
    margin: 0 0 10px 0;
    display: block;
}
figure {
    margin-bottom: 0;
    position: relative;
}
/* img {
    border: 0;
    vertical-align: middle;
} */
ul.umh-city-list a strong {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000;
}
.umh-city-list strong {
    display: block;
    margin-top: 8px;
    font-size: 16px;
    font-weight: bold;
}
.umh-city-list li:hover {
    transform: scale(1.05);
}
/* .wrapper .container {
    position: relative;
} */
/* @media (min-width: 991px) {
    .container {
        width: 1270px;
    }
} */
@media screen and (max-width: 980px) {
    ul.umh-city-list {
        justify-content: flex-start;
    }
}
@media screen and (max-width: 767px) {
    ul.umh-city-list li {
        width: 22%; /* Adjust width to fit better on smaller screens */
    }
}
@media screen and (max-width: 980px) {
    ul.umh-city-list li {
        width: 30%;
        margin: 0px 5px 15px 0;
    }
}
