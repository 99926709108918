.umrah-packages {
  padding: 16px;
  background-color: #e5b622; /* Match the background color */
  color: white; /* Text color */
}

.umrah-container {
  display: flex;
  justify-content: space-between;
}

.umrah-text-column {
  flex: 1;
  max-width: 50%;
  margin-left: 16px; /* Adjusted for left margin */
}

.umrah-image-row {
  display: flex;
  flex: 1;
  gap: 16px; /* Add spacing between the images */
}

.umrah-image-small-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
}

.umrah-heading {
  font-size: 2em;
  color: white; /* Match text color */
}

.umrah-divider {
  height: 1px;
  background-color: #ffffff; /* Match divider color */
  margin: 16px 0;
}

.umrah-text {
  font-size: 1em;
  color: white; /* Match text color */
  margin-bottom: 16px;
 
}

.umrah-image-small {
  width: 100%;
  height: auto;
  border: 3px solid white; /* Add a border around the images */
  border-radius: 4px; /* Optional: Add some border radius */
  margin-top: 6px;
}

.umrah-image-large {
  width: 66%;
  height: calc(85% + 14px);
  border: 5px solid white;
  border-radius: 4px;
  margin-left: 6px;
  margin-top: 6px;
}
.item-p{
  color: #ffffff;
  font-size: 13px;
}
@media (max-width: 768px) {
  .umrah-container {
    flex-direction: column;
  }

  .umrah-text-column, .umrah-image-row {
    max-width: 100%;
    margin-left: 0; /* Adjusted for left margin */
  }

  .umrah-image-small, .umrah-image-large {
    display: none; /* Hide images on small screens */
  }
}

