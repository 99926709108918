.package-block-heading {
  padding: 20px 0;
  text-align: center;
}

.umrah-head {
  margin-bottom: 20px;
}

.it-title {
  color: #45133b;
  /* font-weight: 700; */
  font-family: 'Philosopher', sans-serif;
  padding: 10px 0 5px;
  color: #333;
  margin-top: 36px;
  /* margin-bottom: 10px; */
  text-align: center; /* Ensure both elements have the same text alignment */
}

.it-title h1 {
  color: #000000 !important;
  font-size: 30px;
  font-family: 'Philosopher', sans-serif;
  font-weight: 700;
  margin-bottom: 10px; /* Adjust margin to ensure spacing between h1 and p */
}

.it-title p {
  margin-top: 0; /* Adjust margin to ensure spacing between h1 and p */
}

@media only screen and (max-width: 736px) {
  .it-title h1 {
    font-size: 21px;
    margin-bottom: 18px;
  }
}
