.pagination {
    display: flex;
    list-style: none;
    padding: 0;
  }
  
  .page-item {
    margin: 0 5px;
    font-size: 16px;
    
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
  }
  
  .page-item.active {
    background-color: #007bff;
    color: #fff;
  }
  
  .page-link {
    text-decoration: none;
    color: #333;
  }
  
  .page-link:hover {
    text-decoration: none;
    color: #007bff;
  }
  