ul {
    list-style-type: none;
  }

  .time {
    flex: 1;
    display: flex;
    white-space: nowrap;
}

.line--tip:first-child {
  margin-right: -3px;
}

.line--tip {
  font-weight: 500;
}

.line {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.code {
  margin-top: auto;
  cursor: pointer;
}
.line--middle {
  flex: 1;
}

.direct-label {
  color: #9b9b9b;
  font-size: 13px;
  top: 70%;
  width: 100%;
  position: absolute;
}

.icon-text {
  vertical-align: middle;
}

.line--middle .icon-text {
  font-size: 12px;
  margin-top: -14px;
  display: block;
}

.line span {
  color: #9b9b9b;
  line-height: 1.142857143;
}

.line--tip:last-child {
  margin-left: -3px;
}

.line--tip {
  font-weight: 500;
}

.tooltip {
  display: none;
}

.line--tip span {
  margin-top: 0.85em;
}

.labels {
  position: absolute;
  display: flex;
  left: 100%;
  top: 0;
  margin: 2px 0 0 5px;
}
.line--tip:first-child:before {
  right: -52px;
  left: auto;
}
.ant-collapse .ant-collapse-item .ant-collapse-header{
  padding:0px;
}
.line--tip:before {
  width: 50%;
}
.line:before {
  content: "";
  position: absolute;
  top: 50%;
  border-top: 2px solid #ccc;
  width: 100%;
  left: -40px;
}
.line--tip:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  border: 2px solid #9b9b9b;
  border-radius: 100%;
  background: #fff;
  width: 8px;
  height: 8px;
  transform: translate(-50%,-50%);
  margin-top: 1px;
}
.verticalline{
  border-left: 2px solid #e5b622 ;
    padding-left: 16px
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #e5b622
}

.ant-tabs .ant-tabs-ink-bar{
  background: #e5b622;
}
.tsearch{
  width: 100%;
  

}
@media (min-width: 992px) {
.con{
         box-shadow:0px 0px 40px rgba(0,0,0,0.2);
         border-radius: 20px;
         padding: 20px;
         margin-top: 15px;
         
}

}
@media (max-width: 767px) {
  .con{
           box-shadow:0px 0px 40px rgba(0,0,0,0.2);
           border-radius: 20px;
           padding: 10px;
           margin-top: 15px;
           margin-left: 15px;
           margin-right: 15px;
  }
  }

  @media (min-width: 992px) {
    .conplan{
             box-shadow:0px 0px 40px rgba(0,0,0,0.2);
             border-radius: 20px;
             padding: 30px;
             margin-top: 15px;
             margin: 10px;
             
    }
    
    }
    @media (max-width: 767px) {
      .conplan{
               box-shadow:0px 0px 40px rgba(0,0,0,0.2);
               border-radius: 20px;
               padding: 10px;
               margin-top: 15px;
               margin-left: 15px;
               margin-right: 15px;
      }
      }

      @media (min-width: 992px) {
        .conplans{
                 flex-wrap: unset;
                 
        }
        
        }
        @media (max-width: 767px) {
          .conplans{
                 flex-wrap: wrap;
          }
          }
  @media (min-width: 992px) {
    .cnn{
             box-shadow:0px 0px 10px rgba(0,0,0,0.2);
             border-radius: 20px;
             margin-top: 15px;
             
    }
    }
    @media (max-width: 767px) {
      .cnn{
               box-shadow:0px 0px 10px rgba(0,0,0,0.2);
               border-radius: 20px;
               padding: 10px;
               margin-top: 15px;
           
            
      }
      }
  @media (max-width: 767px) {
    .order1{order:2;}
    .order2 {
      order: 1; /* Change the order for mobile view */
    }
  }
  @media (min-width: 992px) {
    .innerpage-section-padd{
      padding-top: 50px;
      padding-bottom: 60px;
  }
    }
  @media (max-width: 767px) {
  .innerpage-section-padd{
    padding-top: 40px;
    padding-bottom: 147px;
}
  }


.mlk-tag3 {
  cursor: pointer;
  padding: 10px 5px;
  display: inline-block;
  border: 1px solid #ccc;
  margin-right: 5px;
  color: #e5b622/* Default text color */
}
@media (max-width: 767px) {
  .mlk-tag3 {
    cursor: pointer;
    padding: 10px 0px;
    display: inline;
    border: 1px solid #ccc;
    margin-right: 5px;
    color: #e5b622
  }
  }


/* Add your styles for selected items here */

.mlk-tag3.selected {
  border-color: #e5b622; /* Set your desired border color for the selected state */
  border-width: 2px;
  font-weight: bold;
}

.mlk-typography2.selected {
  color: #e5b622; /* Set your desired text color for the selected state */
}

@media (min-width: 992px) {
a.mlk-typography2, .mlk-typography2 a {
    outline: none;
    cursor: pointer;
    font-size: medium;
    transition: color 0.3s;
    text-decoration: none;
}
}
@media (max-width: 767px) {
  a.mlk-typography2, .mlk-typography2 a {
      outline: none;
      cursor: pointer;
      font-size: small;
      transition: color 0.3s;
      text-decoration: none;
  }
  }

.mlk-tag4
 {
 width: 100px;
padding: 5px;
text-align: center;

}
a.mlk-typography4, .mlk-typography4 a {
   
    outline: none;
    cursor: pointer;
    font-size: medium;
    transition: color 0.3s;
    text-decoration: none;
}
@media (min-width: 992px) 
{
.mlk-tag1 {
  cursor: pointer;
  padding: 5px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  margin-right: 5px;
}
}
.mlk-tag1 {
  cursor: pointer;
  padding: 5px 10px;
  display: inline;
  border: 1px solid #ccc;
  margin-right: 5px;
}
.selecteds {
  border-color: #e5b622;
  color: #e5b622;
  font-weight: bold;
}

.semibold {
  font-family: "Panton SemiBold" !important;
}
.boxx
{
padding: 10px 0 0;
width: 120px;
height: 80px;
text-align:left;
}
.sr-btn {
  width: 100%;
  height: 80px;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.boxx2
{
padding: 10px 0 0;
height: 80px;
width: max-content;
text-align: center;
margin-bottom: 20px;
margin-top: 10px;
}
.boxx3
{
padding: 10px 0 0;
height: 80px;
width:max-content;
justify-content: end;
align-items: end;
flex-direction: row;
margin-top: 20px;

}
.tumb {
  height: auto;
  width: 120px;
  margin: auto;
}
.box4 {
  margin: auto;
  padding: 20px;
}
#sr-area .search-grid .grid-body .gridStyle2.h-grid-row
.grid-coll {
  position: relative;
  /* height: 170px; */
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  border-right: 1px solid rgba(0,0,0,.125);
  width: 17%;
 
}

.bold {
  font-family: "Panton Bold" !important;
}
.bottom{
  
  font-size: 16px;
  color: #666666;
}
.top {
  margin-right: 6px;
  font-size: 14px;
}
.semibold {
   font-family: "Panton SemiBold" !important; 
}
.value {
  font-family: "Panton Bold" !important;
}
.darkergray2 {
  color: #333 !important;
}
.f14 {
  font-size: 13px !important;
}
.darkergray {
  color: #323232 !important;
}
.btn.btn-secnd {
  background: #dfe7ed;
  color: #323232;
  margin-right: 5px;
}

.t-row {
  flex-direction: row;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex: 1;
}
@media (min-width: 992px) {
.plan-info{
  margin-top: 30%;
 justify-content: center;
 align-items: center; 
}
}
.valuee {
  color: #333;
  font-family: "Panton Bold";
}

.linkStyle {
  color:#2a3b8f;
  text-decoration: none;
}

.linkStyle:hover {
  color: #e5b622; /* Change this to the desired hover color */
  font-weight: bold;
}
.center2{
  justify-content: center;
  align-items: center;
display: flex;
}
/* For larger screens (laptop) */
@media (min-width: 992px) {
  .borderrightt {
      border-right: 1px solid rgba(0, 0, 0, 0.125);/* Add your desired border style */
      border-bottom: none; /* Remove bottom border for larger screens */
  }
}

/* For smaller screens (mobile) */
@media (max-width: 991px) {
  .borderright {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);/* Add your desired border style */
      border-right: none; /* Remove right border for smaller screens */
     
    }
}
@media (max-width: 500px) {
  .borderbottom {
    
    border-bottom: 1px solid rgba(0, 0, 0, 0.125); /* Add your desired border style */
    border-right: none; /* Remove right border for smaller screens */
  
    }
}
.percentage{
  
  border-radius: 5px;
  border: 1px solid  red;
  padding: 0 2px;
  color: #ff2c2c;
  
 
}
.discount-percentage {
  padding: 0 7px;
}
.h-pro {
  background-color: #fff;
  padding: 10px  10px;
}
@media (min-width: 992px) {
  .tab-navigation {
    padding: 30px 120px;
  }
}

/* For smaller screens (mobile) */
@media (max-width: 991px) {
  .tab-navigation {
    padding: 8px 0px;
  }
}

@media (max-width: 776px) {
.tab-navigation ul.nav-tabs {
  justify-content: space-evenly;
  align-items: center;
  display: -webkit-inline-box;
}
}
@media (min-width: 776px) {
  .tab-navigation ul.nav-tabs {
    justify-content: space-evenly;
    align-items: center;
    
  }
}
@media (max-width: 776px) {
  .planimg {
    width: -webkit-fill-available !important;
  }
  }


.nav-tabs {
  border-bottom: none;
}
.tab-navigation ul.nav-tabs li a {
  background: #fff;
  z-index: 1;
  padding: 0;
  border: none;
}
.tab-navigation ul.nav-tabs li .s-no {
  height: 35px;
  background-image: url(../public/images/checkout-border-gray.png);
  background-position: center;
  margin: 0px auto 4px;
  background-repeat: no-repeat;
  background-size: 42px;
  width: 100px;
}
.tab-navigation ul.nav-tabs li.active .s-no {
  background-image: url(../public/images/checkout-fill.png);
 
}
.tab-navigation ul.nav-tabs li.active .tab-name h5 {
  color: #ef9807;
  font-weight: bold;
  font-family: "Panton SemiBold";
}

.tab-navigation ul.nav-tabs li .tab-name h5 {
  text-align: center;
  font-size: 13px;
  color: #aaa;
  margin-bottom: 0;
  font-family: "Panton SemiBold";
}
.tab-navigation ul.nav-tabs li .tab-name h5 {
  font-size: 12px;
}
.innerpage-section-paddingg {
  padding-top: 20px;
  padding-bottom: 147px;
}
.nav-tabs>li {
  /* float: left; */
  margin-bottom: -1px;
  margin-left: 5px;
}

.nav>li {
  position: relative;
  display: block;
}
.ppp {
  line-height: 1.6em;
  font-size: 18px;
  margin-bottom: 24px;
  color: #333;
  margin-left: 15px;
  
}
.btn-controler {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  border-top: solid 1px #ddd;
  padding-top: 10px;
}


.box ul li {
  width: 100%;
  display: flex;
  justify-content:space-between;
  margin-bottom: 15px;
  font-size: 14px;
}
.box ul li .lbl {
  font-family: "Panton SemiBold";
  width: 50%;
  line-height: 2;
}
.box ul li .value {
  font-family: "Panton Bold";
  width: 50%;
  text-align: right;
}
.box ul li .discount-txt-wrap {
  width: 50%;
  text-align: right;
  font-size: 18px;
  font-family: "Panton Bold";
}
.basecolor1 {
  color: #e5b622 !important;
}
.containerrs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.left-corner,
.right-corner {
  padding: 5px;
}

.percentagee {
  border-radius: 5px;
  color: #ff2c2c;
}

.discount-areaa {
  font-size: 14px;
  color: #373737;
  text-align: right;
  display: flex;
}

.discount-price {
  opacity: 0.7;
}
.pricce {
  color: #ef9807;
  font-family: Panton Bold;
  font-size: 18px;
  text-align: right;
}

/* Media query for mobile view */
@media (max-width: 600px) {
  .containerrs {
    flex-direction: column; /* Stack elements vertically on small screens */
  }

  .left-corner,
  .right-corner {
    width: 100%; /* Make the corners take full width on small screens */
    box-sizing: border-box; /* Include padding and border in the element's total width */
    text-align: left; /* Adjust text alignment for better readability on small screens */
    margin-bottom: 10px; /* Add some space between elements */
  }
  .pricce {
    color: #ef9807;
    font-family: Panton Bold;
    font-size: 18px;
    text-align: left;
  }
}
.percentage_age{
  
  border-radius: 5px;
  border: 2px solid  red;
  padding: 0 2px;
  color: #ff2c2c;
  font-weight: 600;
  height: 34px;;
  width: 80px;
  margin-top: 2px;
  
 
}
.form-value-list {
  width: 50%;
}
@media (max-width: 600px)
{
 .form-value-list {
    width: 100%;
}
}
.form-value-list .inner {
  background: #fff;
  margin-bottom: 10px;
  color: #333;
  height: 60px;
}
.form-value-list .inner .field-lbl {
  font-size: 18px;
  font-family: Panton Regular;
}
.form-value-list .inner .field-value {
  font-size: 18px;
  font-family: "Panton Bold";
  font-weight: bold;
}

/* For larger screens (laptop) */
/* Styles for screens with width greater than or equal to 992px */
@media (min-width: 992px) {
  .btn2 {
    border: 0px; /* Add your desired border style */
   
    border-radius: 5px;
    padding: 5px;
  }
}

/* Styles for screens with width less than 992px (mobile) */
@media (max-width: 991px) {
  .btn2 {
    border: 0px; /* Add your desired border style for mobile */
  }
}
@media (min-width: 991px) {
.nav2 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
}
@media (max-width: 767px) {
  .nav2 {
    display: -ms-flexbox;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  }
.tabs
{
margin: 0px;
display: flex;
margin-bottom:20px;
border-bottom: 1px solid #ccc;

}

@media (min-width: 991px) {
.tabs .tab.active {
  background: #fff;
  height: 60px;
  width: 200px;
  padding: 5px 0 0;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  display: flex;
  font-weight: bold;
  color: rgb(68, 68, 68);
}
.tabs .tab {
  height: 60px;
  width: 200px;
  background-color: #ef9807;
  color: #fff;
  padding: 5px 0 0;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  display: flex;
  border-left: 1px solid #ccc;
}
}
@media (max-width: 767px) {
  .tabs .tab.active {
    background: #fff;
    height: 60px;
    width: 200px;
    padding: 5px 0 0;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
    display: flex;
   
    font-size: smaller;
    color: rgb(68, 68, 68);;
  }
  .tabs .tab {
    height: 60px;
    width: 200px;
    background: #ef9807;
    color: #fff;
    padding: 5px 0 0;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
    display: flex;
    font-size: smaller;
    border-left: 1px solid #ccc;
  }
  }



.cardd {
  margin-top: 1em;
  /* padding: 0.5em 0.5em 0.5em; */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  
    

}
@media (min-width: 992px) {
.cardds {
  margin-top: 1em;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
}
@media (max-width: 767px) {
  .cardds {
    margin-top: 1em;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
   border-radius: 20px;
   padding: 10px;
   margin-left: 10px;
   margin-right: 10px;
  }
  }
  @media (min-width: 992px) {
    .cardds {
      margin-top: 1em;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
     
    }
    }
  @media (max-width: 767px) {
    .carddss {
      margin-top: 1em;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
     border-radius: 20px;
     padding: 10px;
     margin-left: 10px;
     margin-right: 10px;
    }
    }
@media (min-width: 992px) {
  .h-proo {
   background-color: rgb(238, 238, 238);
    padding: 10px  10px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
@media (max-width: 767px) {
  .h-proo {
    background-color: #ffffffe8;
    padding: 10px  10px;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.custom-radio {
  width: 20px; 
  height: 20px; 
}

.subnav ul {
  margin: 0;
  padding: 0;
  list-style: none none outside;
}
.subnav ul li {
  display: block;
  background-color: #CCCCFF;
  padding: 0;
}
.subnav ul li a {
  display: block;
  font-size: 13px;
  line-height: 16px;
  padding: 7px 2px 7px 25px;
  color: rgb(68, 68, 68);
  background-color: rgb(204, 204, 255);
  border-bottom: 1px solid #ffffff;
}
.service ul, .service li {
  list-style: none none outside;
  padding: 0;
  margin: 0;
  background-color: #eeeeee;
}
.service li {
  list-style: none none outside;
  padding: 0;
  margin: 0;
  background-color: #eeeeee;
}
.service ul li a {
  display: block;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 5px 8px 22px;
  margin-left: 1px;
  margin-right: 1px;
  border-bottom: 2px dotted #999999;
}
.pbb-80 {
  padding-bottom: 40px !important;
}
.plan-80 {
  padding-bottom: 40px !important;
  padding-top: 60px;
  
  padding: 20px;
}

.plan-800 {
  padding-bottom: 40px !important;
  padding-top: 60px;
}
@media (min-width: 992px)
{

}
section.coverage .container .coverage-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 30px;
}
section.coverage .container .coverage-wrap-item {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
  border: 1px solid #c8cacd;
  border-radius: 16px;
  padding: 20px;
  width: calc(25% - 13.3333333333px);
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}
section.coverage .container .coverage-wrap-items {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
  border: 1px solid #c8cacd;
  border-radius: 16px;
  padding: 20px;
  width: calc(25% - 13.3333333333px);
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}
/* For desktops and laptops */
@media only screen and (min-width: 768px) {
  section.coverage .container .coverage-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 30px;
    flex-wrap: wrap; /* Allow items to wrap onto multiple lines */
  }
  section.coverage .container .coverage-wrap-item {
    width: calc(25% - 13.3333333333px);
  }
  section.coverage .container .coverage-wrap-items {
    width: calc(50% - 13.3333333333px);
  }
}

/* For mobile devices */
@media only screen and (max-width: 767px) {
  section.coverage .container .coverage-wrap {
    display: block; /* Display items in a single column */
  }
  section.coverage .container .coverage-wrap-item {
    width: 100%; /* Occupy full width of the container */
    margin-bottom: 20px; /* Add some space between items */
  }
  section.coverage .container .coverage-wrap-items {
    width: 100%; /* Occupy full width of the container */
    margin-bottom: 20px; /* Add some space between items */
  }

}

section.coverage .container .coverage-wrap-item h3 {
  font-size: 24px;
  line-height: 33.6px;
  font-weight: 500;
  color: #000;
  margin-bottom: 10px;
  max-width: 230px;
  font-family: "Roboto", sans-serif;
}
section.coverage .container .coverage-wrap-item p {
  font-size: 16px;
  line-height: 22.4px;
  color: #2e2e2e;
  margin-top: 40px;
  position: relative;
  font-family: "Roboto", sans-serif;
}
section.coverage .container .coverage-wrap-item p:before {
  content: "";
  position: absolute;
  top: -20px;
  left: 0;
  width: 50px;
  height: 2px;
  background-color: #000;
}
section.coverage .container .coverage-wrap-item:hover {
  -webkit-transform: scale(1);
  transform: scale(1);
  background-color: #e5b815;
  -webkit-box-shadow: 10px 10px 50px rgba(34,34,34,0.2);
  box-shadow: 10px 10px 50px rgba(34,34,34,0.2);
}
section.coverage .container .coverage-wrap-item:hover p {
  color: #fff;
}
section.coverage .container .coverage-wrap-item:hover h3 {
  color: #fff;
}
section.coverage .container .coverage-wrap-item:hover p:before {
  background-color: #fff;
}


section.coverage .container .coverage-wrap-items h3 {
  font-size: 24px;
  line-height: 33.6px;
  font-weight: 500;
  color: #000;
  margin-bottom: 10px;
  max-width: 230px;
  font-family: "Roboto", sans-serif;
}
section.coverage .container .coverage-wrap-items p {
  font-size: 16px;
  line-height: 22.4px;
  color: #2e2e2e;
  margin-top: 40px;
  position: relative;
  font-family: "Roboto", sans-serif;
}
section.coverage .container .coverage-wrap-items p:before {
  content: "";
  position: absolute;
  top: -20px;
  left: 0;
  width: 50px;
  height: 2px;
  background-color: #000;
}
section.coverage .container .coverage-wrap-items:hover {
  -webkit-transform: scale(1);
  transform: scale(1);
  background-color: #e5b815;
  -webkit-box-shadow: 10px 10px 50px rgba(34,34,34,0.2);
  box-shadow: 10px 10px 50px rgba(34,34,34,0.2);
}
section.coverage .container .coverage-wrap-items:hover p {
  color: #fff;
}
section.coverage .container .coverage-wrap-items:hover h3 {
  color: #fff;
}
section.coverage .container .coverage-wrap-items:hover p:before {
  background-color: #fff;
}
/* @media (min-width: 1200px)
{
  section.coverage .container
  {
    max-width: 1270px;
  }
} */

@media (min-width: 768px)
{
.md\:py-\[3\.75rem\] {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
}
}
.py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}
.bg-\[\#F9FAFC\] {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 252 / var(--tw-bg-opacity));
}
@media screen and (min-width: 768px)
{
  .bg-img-flight {
    background: url(../public/images/airplan.png) no-repeat -32% 105%;
}
}

@media (min-width: 1024px)
{
  .lg\:gap-\[6\.25rem\] {
    gap: 6.25rem;
}
}
@media (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}
@media (min-width: 768px) {
  .mar_main {
    margin-left: 70px;
    margin-right: 70px;
  }
}
@media (min-width: 768px) {
  .mar_main2 {
    margin-left: 70px;
    margin-right: 70px;
  }
}
@media (min-width: 1024px)
{
  .lg\:flex-row {
    flex-direction: row !important;
}
}
.justify-between {
    justify-content: space-between;
}
.flex-col {
    flex-direction: column;
}
.flex {
    display: flex;
}

@media (min-width: 1024px)
{
  .lg\:max-w-\[37\.5rem\] {
    max-width: 37.5rem;
}
}
@media (min-width: 1024px)
{
.lg\:text-44 {
    font-size: 44px !important;
}
}
.leading-1-2 {
    line-height: 1.2;
}
@media (min-width: 1024px)
{
.lg\:text-44 {
    font-size: 44px !important;
}
}
.leading-1-2 {
    line-height: 1.2;
}
.font-bold {
    font-weight: 700;
}
.text-28 {
    font-size: 28px;
}
.mb-4 {
    margin-bottom: 1rem;
}

@media (min-width: 768px)
{
.md\:text-18 {
    font-size: 18px;
}
}

.leading-1-4 {
    line-height: 1.4;
}
.text-16 {
    font-size: 16px;
}
@media (min-width: 768px)
{
.md\:text-16 {
    font-size: 16px;
}
}

.text-red-gradient {
    background: linear-gradient(90deg, #e5b815, #e5b815);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.leading-1-4 {
    line-height: 1.4;
}
.text-14 {
    font-size: 14px;
}
.gap-3 {
    gap: .75rem;
}
.items-center {
    align-items: center;
}
.flex {
    display: flex;
}

@media (min-width: 768px)
{
.md\:gap-y-\[4\.125rem\] {
    row-gap: 4.125rem;
}
}
@media (min-width: 768px)
{
.md\:max-w-\[35rem\] {
    max-width: 35rem;
}
}
.gap-y-\[1\.875rem\] {
    row-gap: 1.875rem;
}


.relative {
    position: relative;
}
@media (min-width: 768px)
{
.md\:gap-x-\[1\.875rem\] {
    -moz-column-gap: 1.875rem;
    column-gap: 1.875rem;
}
}
.gap-x-5 {
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
}

@media (min-width: 768px)
{.md\:w-2 {
    width: .5rem;
}}

@media (min-width: 768px)
{.md\:left-\[39px\] {
    left: 39px;
}}
@media (min-width: 640px)
{.sm\:bottom-16 {
    bottom: 4rem;
}}
.w-\[6px\] {
    width: 6px;
}
.-z-10 {
    z-index: -10;
}





@media (min-width: 768px)
{
.md\:text-44 {
    font-size: 44px;
}
}
@media (min-width: 768px)
{
.md\:mt-14 {
    margin-top: 3.5rem;
}
}
@media (min-width: 768px)
{
.md\:gap-x-4 {
    -moz-column-gap: 1rem;
    column-gap: 1rem;
}
}
@media (min-width: 768px)
{.md\:mb-10 {
    margin-bottom: 2.5rem;
}
}
.mb-6 {
  margin-bottom: 1.5rem;
}
@media (min-width: 768px)

{
  .md\:text-16 {
    font-size: 16px;
    font-family: Poppins, sans-serif;
}
}
@media (min-width: 768px)
{
.md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}
}
.bg-\[\#F1F1F1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 241 241 / var(--tw-bg-opacity));
  border: 0px;
}
@media (max-width: 768px)
{
.px-\[1\.2188rem\] {
  padding-left: 1.2188rem;
  padding-right: 1.2188rem;
}
}
.rounded-full {
  border-radius: 9999px;
}

.justify-center {
  justify-content: center;
}
.h-14 {
  height: 3.5rem;
}
.font-semibold {
  font-weight: 600;
}
[type=button],  button {
  -webkit-appearance: button;
  background-color: #d3cdb6;
  background-image: none;
  border: 0px;
  margin: 10px;

}
@media (min-width: 768px)
{
.md\:gap-6 {
    gap: 1.5rem;
}
}
.gap-\[15px\] {
    gap: 15px;
}
.grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
}
.grid {
    display: grid;
}
@media (min-width: 768px)
{
.md\:col-span-3 {
    grid-column: span 3 / span 3;
}
}
@media (max-width: 768px)
{
  .col-span-6 {
    grid-column: span 6 / span 6;
}
}
.coun-a {
  color: inherit !important;
  text-decoration: inherit !important;
  
}
.shadow-secondary {
  --tw-shadow: 0px 2px 8px 0px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0px 2px 8px 0px var(--tw-shadow-color);
}
.shadow-primary, .shadow-secondary {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.rounded-2xl {
  border-radius: 1rem;
}

.overflow-hidden {
  overflow: hidden;
}
.w-full {
  width: 100%;
}
@media (min-width: 768px)
{
.md\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
}
}
@media (min-width: 768px)
{
.md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
}
.p-\[10px\] {
    padding: 10px;
}

.flex-col {
    flex-direction: column;
}
.h-\[3\.75rem\] {
    height: 3.75rem;
}

@media (min-width: 1024px)
{
.lg\:mt-8 {
    margin-top: 2rem;
}
}
@media (min-width: 1024px)
{
.mt-6 {
  margin-top: 1.5rem;
}
}
.pagination_wrapper__DjRBx {
  display: flex;
  justify-content: center;
  gap: .5rem;
  flex-wrap: wrap;
}
.pagination_next__Kwhs7, .pagination_previous__hXUm3 {
  color: #363636;
  background-color: #fff;
  font-size: .875rem;
  width: 2rem;
  height: 2rem;
  border: 1px solid #eaeaea;
  border-radius: .125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all .3s ease-in-out;
}
.pagination_previous__hXUm3 a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  line-height: 1;
}
.pagination_item__Vm94q {
  color: #363636;
  font-size: .875rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination_item__Vm94q {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: .125rem;
  cursor: pointer;
  transition: all .3s ease-in-out;
}
.pagination_item__Vm94q.pagination_active__NVytL {
  color: #e60000;
  border-color: #e60000;
}
.pagination_next__Kwhs7:hover, .pagination_previous__hXUm3:hover {
  border-color: #e60000;
  transition: all .3s ease-in-out;
}
.pagination_next__Kwhs7.pagination_disabled__Lcs1w, .pagination_next__Kwhs7.pagination_disabled__Lcs1w a[role=button], .pagination_previous__hXUm3.pagination_disabled__Lcs1w, .pagination_previous__hXUm3.pagination_disabled__Lcs1w a[role=button] {
  cursor: not-allowed;
  border-color: #eaeaea;
}
.pagination_item__Vm94q:hover, .pagination_next__Kwhs7:hover, .pagination_previous__hXUm3:hover {
  border-color: #e60000;
  transition: all .3s ease-in-out;
}



@media (min-width: 768px) {
  .md\:pt-10 {
      padding-top: 2.5rem;
  }
}

@media (min-width: 768px) {
  .md\:pb-0 {
      padding-bottom: 0;
  }
}
@media (min-width: 768px) {
  .md\:gap-6 {
      gap: 1.5rem;
  }
}
.py-\[1\.875rem\] {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
.gap-4 {
  gap: 1rem;
}
.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
.grid {
  display: grid;
}
@media (min-width: 1024px) {
  .lg\:col-span-4 {
      grid-column: span 3 / span 3;
  }
}
@media (max-width: 768px) {
.col-span-12 {
  grid-column: span 12 / span 12;
}
}

@media (min-width: 768px) {
  .md\:p-8 {
      padding: 2rem;
  }
}
.shadow-secondary {
  --tw-shadow: 0px 2px 8px 0px rgb(0 0 0 / 32%);
  --tw-shadow-colored: 0px 2px 8px 0px var(--tw-shadow-color);
}

.shadow-primary, .shadow-secondary {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
@media (min-width: 768px) {
  .md\:w-\[96px\] {
      width: 96px;
  }
}
@media (min-width: 768px) {
  .md\:h-\[96px\] {
      height: 96px;
  }
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.rounded-\[40px\] {
  border-radius: 40px;
}

.paymentactive {
  background-color: #e5b815;
  color: white;
}

.paymentactive h3,
.paymentactive p {
    color: white !important;
}
@media (max-width: 768px) {
.lefttab {
width: 100px;
}
}

@media (max-width: 768px) {
.fass {
  padding: 10px;
  font-size: 30px;
  margin-left: 10px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
}
}
@media (min-width: 991px) {
  .fass {
    padding: 20px;
    font-size: 30px;
    margin-left: 10px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
  }
  }

/* Add a hover effect if you want */
.fa:hover {
  opacity: 0.7;
  color: #e5b622;
}

.facebook {
  background: #3B5998;
  color: white;
}

.twitter {
  background: #55ACEE;
  color: white;
}
.linkedin {
  background: #007bb5;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}

.instagram {
  background: #125688;
  color: white;
}

@media (min-width: 991px) {
.mlk-rowes {
  display: flex;
  flex-flow: row wrap;
}
}

.css-jpln7h-MuiTabs-scroller {
  position: relative;
  display: inline-block;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: auto !important;
  width: 100%;
}