  .enquiry-main {
    padding: 40px;
    background-image: url('../../../public/images/umrah/Umrah.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
}

.enquiry-container {
  max-width: 950px;
  margin: 0 auto;
  padding: 12px;
  background-color: #faf6f6eb;
  border-radius: 8px;
  margin-top: 5px;
}

.tab-header {
  display: flex;
  margin-bottom: 8px;
}

.tab {
  padding: 10px 20px;
  background-color: #e0e0e0;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.tab.active {
  background-color: rgb(229, 184, 21);
  border-radius: 8px;
  color: white;
  text-decoration: none;
}


@media (min-width: 991px) {
.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}
}



@media (max-width: 736px) {
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

}
@media  (max-width: 736px) {
  .departureAirport {
      width: 50%;
      float: left;
  }
}
@media (max-width: 736px) {
  .depDate {
      float: left;
      width: 50%;
  }
}
@media  (max-width: 736px) {
  .makkahNights {
      float: left;
      width: 50%;
  }
}
@media (max-width: 736px) {
  .madinaNights {
      float: left;
      width: 50%;
  }
}
@media (max-width: 736px) {
  .noOfTravelers {
      float: left;
      width: 100%;
  }
}
@media (max-width: 600px) {
  ul.noOfTraveler {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      width: 100%;
      background: none;
  }
}
@media (max-width: 600px) {
  ul.noOfTraveler li {
      flex: 0 33.3333%;
  }
}
@media (max-width: 736px) {
  .hotelType {
      float: left;
      width: 50%;
  }
}
.wpcf7-form select {
  width: 100%;
  height: 30px;
  line-height: 9px;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 3px 20px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: normal !important;
}
@media  (max-width: 736px) {
  .leadPassengerName {
      float: left;
      width: 50%;
  }
}
@media (max-width: 736px) {
  .personEmail {
      float: left;
      width: 50%;
  }
}
@media  (max-width: 736px) {
  .contactNumber {
      float: left;
      width: 50%;
  }
}

@media (max-width: 736px) {
  .btnSubmit {
      width: 100%;
      text-align: center;
  }
}


.madinah-makkah, .makkahNight-slect {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: 14px;
}

.travelers {
  flex: 2;
}

.traveler-inputs {
  display: flex;
  gap: 10px;
}

.traveler-inputs select {
  flex: 1;
}

.submit-btn {
  background-color: rgb(229, 184, 21);
  color: #ffffff;
  width: 127px;
  padding: 3px 8px;
  border: none;
  margin-top: 21px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  
  font-weight: 600;
  transition: background-color 0.3s;
}

/* .submit-btn:hover {
  background-color: #0056b3;
} */



ul.nav.nav-justified.noOfTraveler {
  margin: 0;
}
.nav-justified {
  /* color: white !important; */
  font-weight: 700;
  margin-top: -42px;
  border-radius: 4px;
}
.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-justified {
  width: 120%;
}
/* ul{
  font-size: 12px;
} */
@media (min-width: 768px) {
  .nav-justified>li {
      display: table-cell;
      width: 32%;
  }
}

.nav-justified>li {
  float: none;
}
#some-t{
  padding: 2px;
}

/* Button Stying */

#lead_passe {
  padding: 0 !important;
}
.noOfTraveler {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.noOfTraveler li:last-child {
  margin-right: 0; /* Remove margin from the last item */
}



@media (min-width: 992px) {
  .col-lg-3 {
      flex: 0 0 auto;
      /* width: 24% !important; */
  }
}
/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .enquiry-container {
    padding: 15px;
    max-width: 90%;
  }

  .enquiry-container h2 {
    font-size: 20px;
  }

  .enquiry-container input,
  .enquiry-container textarea,
  .enquiry-container select {
    font-size: 12px;
    padding: 8px;
  }

  .enquiry-container button {
    padding: 8px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .enquiry-container {
    padding: 10px;
    max-width: 100%;
  }

  .enquiry-container h2 {
    font-size: 18px;
  }
  .nav-justified {
    width: 105%;
}

  .enquiry-container input,
  .enquiry-container textarea,
  .enquiry-container select {
    font-size: 10px;
    padding: 10px;
  }

  .enquiry-container button {
    padding: 6px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 736px) {
  .departureAirport {
      width: 50%;
      float: left;
  }
}

@media only screen and (max-width: 600px) {
  .inquiryFormContainer div {
      margin: 0px;
  }
}
@media (max-width: 768px) {
  .enquiry-main {
    background-image: url('../../../public/images/umrah/Img1.png'); /* Image for mobile screens */
    background-size: cover;
    background-position: center;
    
  }
}