/* UmrahPackages.css */
.umh-duration-sec {
    padding: 60px 0;
    background-color: #f9f9f9;
}

.umh-heading {
    text-align: center;
    margin-bottom: 30px;
}

.umh-heading h2 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 10px;
}

.umh-heading p {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 0;
}

ul.umh-duration-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

ul.umh-duration-list li {
    width: 125px;
    padding: 0 10px;
    margin: 0;
    list-style: none;
}

ul.umh-duration-list li a, ul.umh-duration-list li strong {
    display: block;
    border-radius: 10px;
    border: 3px solid #F4CE14;
    text-align: center;
    background: #FFFAE6;
    font-size: 41px;
    line-height: 1;
    padding: 10px 0 10px 0;
    color: #000;
    text-decoration: none !important;
    font-weight: normal;
}

ul.umh-duration-list li a:hover {
    color: #FFB200;
    border-color: #FFB200;
}

ul.umh-duration-list li a em, ul.umh-duration-list li strong em {
    font-style: normal;
    display: block;
    font-size: 16px;
    line-height: 24px;
    padding: 5px 0 0 0;
}
ul.umh-duration-list li a span {
    font-style: normal;
    display: block;
    font-size: 15px;
    line-height: 21px;
    padding: 0px 5px 6px;
    font-weight: 600;
}

/* .umh-duration-list a span:hover {
    background-color: #218838;
} */


@media screen and (max-width: 980px) {
    ul.umh-duration-list li {
        width: 33.3%;
        padding: 0 6px 10px;
    }
}
@media (max-width: 768px) {
    .umh-duration-list {
        /* flex-direction: column; */
        align-items: center;
    }
}