.footer-top-unique {
  background: #e5b622;
  color: #ffffff;
  font-weight: 600;
  padding: 25px 0;
  font-family: 'Philosopher', sans-serif !important;
  font-size: 18px;
  /* margin-top: 22px; */
}

.contact-info-unique {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.contact-title-unique {
  flex: 1;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center; /* Centered on mobile screens */
}

.contact-item-unique {
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contact-item-unique a {
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
}

.icon-unique {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  fill: currentColor;
}

.color-unique {
  color: #ffffff; /* Blue color for links */
}

.clearfix-unique::after {
  content: "";
  display: table;
  clear: both;
}

/* Mobile Styles */
@media (max-width: 767px) {
  .contact-info-unique {
      flex-direction: column;
      align-items: center;
  }

  .contact-item-unique {
      margin-bottom: 15px; /* Add more spacing between items */
  }
}
