.react-autosuggest__input {
    border: none !important; 
    height: 38px;
    width: 100%;
  }
  .react-autosuggest__suggestions-container {
    position: absolute;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 99999;
   
  }
  
  .react-autosuggest__suggestion {
    padding: 10px;
    
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #f0f0f0;
   
  }