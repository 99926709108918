.banner-padding {
    padding: 60px 0;
  }
  
  /* .container {
    max-width: 1200px;
    margin: 0 auto;
  } */
  
  /* .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  } */
  
  .j-heading {
    font-size: 2.5rem;
    margin-bottom: 40px;
    text-align: center;
  }
  
  .b-feature-block {
    padding: 15px;
    margin: 15px 0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .b-feature-block span {
    font-size: 24px;
    display: block;
    margin-bottom: 10px;
  }
  
  .b-feature-block h3 {
    font-size: 18px;
    margin: 10px 0;
  }
  
  .b-feature-block p {
    font-size: 14px;
    margin: 10px 0;
    /* color: #fff */
  }
  
  .j-heading{
    font-size: 2.5em;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .b-feature-block img {
    width: 50px; /* Adjust the size of the icons as needed */
    height: auto;
    margin-bottom: 10px;
  }
  
  .thumb-u {
    background: #e5b622;
    color: rgb(28, 28, 28);
    margin-top: 20px;
  }
  
  .thumb-u img {
    filter: brightness(0) invert(1); /* Adjust if needed to match your design */
  }
  
  @media (min-width: 768px) {
    .col-md-6.col-lg-4 {
      padding: 0 10px;
    }
  }
  
  @media (max-width: 767px) {
    .b-feature-block {
      padding: 10px;
      margin: 10px 0;
    }
  
    .b-feature-block h3 {
      font-size: 16px;
    }
  
    .b-feature-block p {
      font-size: 12px;
    }
  
    .j-heading {
      font-size: 2.5em;
    }
  }
  