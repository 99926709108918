.content-section {
    flex: 1;
    /* Adjust width based on layout needs */
  }
  
  .image-section {
    flex: 0 0 300px; /* Adjust the width as needed */
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  
  .img-display {
    margin-top: 40px;
    margin-left: 20px;
    /* max-width: 100%; */
    height: auto;
    display: block;
    border-radius: 5%;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
  
    .image-section {
      flex: none;
      margin-top: 20px; /* Space between content and image on smaller screens */
    }
  }
  
  
  @media (max-width: 767px) {
    .image-section  {
      display: none; /* Hide the image on small screens */
    }
  }