.umrah-enquiry-main {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 70vh; */
}
/* @media(min-width:991px) {
  .umrahform{
    display:flex ;
  }
} */
@media (max-width: 768px) {
  .umrahh-coll {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  }
  
  @media (max-width: 768px) {
  .umrah-form-row {
    width: 100%;
  }
  }
.umrah-enquiry-container {
  max-width: 950px;
  margin: 0 auto;
  width: 67%;
  padding: 12px;
  background-color: #e0e0e0;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 29px;
}

.umrah-form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

/* .umrah-col {
  flex: 1;
  min-width: 200px;
} */



.submit-btn {
  background-color: rgb(229, 184, 21);
  color: #ffffff;
  width: 127px;
  padding: 3px 8px;
  border: none;
  margin-top: 21px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  transition: background-color 0.3s;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.submit-btn:hover {
  background-color: #d4a518;
}

.umrah-h3 {
  margin: 0 0 8px;
  /* text-transform: uppercase;
  text-align: center; */
  color: #000000;
  font-weight: bold;
  font-size: 24px;
  padding: 8px 0;
}
.UmrahCity
{
 width: 100% !important;
  height:40px !important
}


/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .umrah-enquiry-container {
    padding: 15px;
    max-width: 90%;
  }

  .umrah-enquiry-container h3 {
    font-size: 20px;
  }

  .umrah-enquiry-container input,
  .umrah-enquiry-container select {
    font-size: 12px;
    padding: 8px;
  }

  .umrah-enquiry-container .submit-btn {
    padding: 8px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .umrah-enquiry-container {
    padding: 10px;
    max-width: 100%;
  }

  .umrah-enquiry-container h3 {
    font-size: 18px;
  }

  .umrah-enquiry-container input,
  .umrah-enquiry-container select {
    font-size: 10px;
    padding: 2px;
  }

  .umrah-enquiry-container .submit-btn {
    padding: 6px;
    font-size: 12px;
  }
  .umrah-enquiry-container {
    max-width: 950px;
    margin: 0 auto;
     width: 100% !important; 
    padding: 12px;
    background-color:#e0e0e0;
    border-radius: 8px;
    margin-top: 5px;
    margin-bottom: 29px;
}
}

