.custom-ol {
  list-style-type: none;
  counter-reset: custom-counter;
  line-height: 40px;
}
@media screen and (max-width:440px) {
  .margint{
    margin-top: 10px;
  }
}


/* .custom-ol li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}

.custom-ol li::before {
  content: "";
  position: absolute;
  left: 2px;
  top: 5px;
  width: 15px;
  height: 15px;
  border: 1px solid #000;
  border-radius: 50%;
  background-color: #fff;
}


@media screen and (max-width:440px) {
  .custom-ol li::after{
    left: 2.8%;
  }
} */