.banner-sec {
    position: relative;
    overflow: hidden;
    padding: 20px 0;
    background-color: #e5b622;
  }
  .city-margin{
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .banner-sec .container {
    position: relative;
    z-index: 1;
  }
  
  .detail-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .text-holder {
    flex: 1;
    padding: 20px;
    max-width: 50%;
  }
  
  .text-holder h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #fff;
  }
  
  .text-holder p {
    font-size: 1rem;
    line-height: 1.6;
    color: #fff;
  }
  
  .img-holder {
    flex: 1;
    padding: 20px;
    max-width: 50%; 
    box-sizing: border-box;
  }
  
  .img-holder figure {
    margin: 0;
  }
  
  .img-holder img {
    max-width: 100%;
    height: auto;
    display: block;
    border-radius: 15px;
  }
  
  @media (max-width: 768px) {
    .detail-holder {
      flex-direction: column;
      /* text-align: center; */
    }
  
    .text-holder, .img-holder {
      max-width: 100%;
      padding: 10px;
    }
  
    .text-holder h1 {
      font-size: 1.5rem;
      color: #fff;
    }
  
    .text-holder p {
      font-size: 0.875rem;
      color: #fff;
    }
  }
.city-btn1{
  border-radius: 15px;
  margin-left: 0px !important;
  padding: 8px;
  padding-right: 26px;
  padding-left: 26px;
  font-weight: 700;
}
