
  
  .image-container-u {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .image-unique {
    max-width: 100%;
    height: 89%;
    border-radius: 8px;
  }
  
  .icon-list {
    width: 100%;
    max-width: 800px;
  }
  
  .icon-list-items {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: 15px;
  }
  
  .icon-list-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .icon-list-icon {
    margin-right: 15px;
  }
  
  .icon-uniq {
    width: 24px;
    height: 24px;
    fill: #4caf50; /* Green color for icons */
  }
  
  .icon-list-text {
    font-size: 14px;
    color: #333; /* Dark color for text */
  }
  

    
  @media (max-width: 767px) {
    .image-unique  {
      display: none; /* Hide the image on small screens */
    }
  }