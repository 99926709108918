.top-content {
    padding: 16px;
    background-color: #e5b622 ; /* Match the background color */
    color: white; /* Text color */
  }
  
  .custom-container {
    display: flex;
    justify-content: space-between;
  }
  
  .text-column {
    flex: 1;
    max-width: 50%;
    margin-right: 16px;
  }
  
  .image-row {
    display: flex;
    flex: 1;
    gap: 16px; /* Add spacing between the images */
  }
  
  .image-small-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
  }
  
  .heading {
    font-size: 2em;
    color: white; /* Match text color */
  }
  
  .divider {
    height: 1px;
    background-color: #ffffff; /* Match divider color */
    margin: 16px 0;
  }
  
  .text {
    font-size: 1em;
    color: white; /* Match text color */
    margin-bottom: 16px;
  }
  
  .image-small {
    width: 100%;
    height: auto;
    border: 3px solid white; /* Add a border around the images */
    border-radius: 4px; /* Optional: Add some border radius */
  }
  
  .image-large {
    width: 71%;
    height: calc(87% + 16px);
    border: 6px solid white;
    border-radius: 4px;
  }
  
  @media (max-width: 768px) {
    .custom-container {
      flex-direction: column;
    }
  
    .text-column, .image-row {
      max-width: 100%;
      margin-right: 0;
    }
  
    .image-small, .image-large {
      display: none; /* Hide images on small screens */
    }
  }
  