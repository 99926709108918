.popup-overlay {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  /* Optional: Add styles for the close button */
  .popup-content button {
    margin-top: 10px;
  }
  .MainSlip{
    background-color: #0867d2;
    height: 38px;
    text-align: left;
  }
  .SlipBackground{
    background-color: #dfdfdf;
    text-align: left;
  }