unique-image-section {
    width: 100%;
  }
  
  .unique-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .unique-text-section h2 {
    margin-top: 20px; /* Adjust spacing below the title */
  }
  
  .unique-text-section p {
    margin-bottom: 15px; /* Adjust spacing between paragraphs */
  }
  
 @media (max-width: 767px){
  .unique-image {
    margin-top: 30px;
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}
 }