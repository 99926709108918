.rcorners2 {
    border-radius: 25px;
    border: 2px solid #FAA61A;
    margin: 20px;
    margin-left:60px;
    padding:20px;
  }
  .rcorners22 {
    margin-left:60px;
    padding: 0px 0px 0px 18px;
  }
  .rcornersflight {
    border-radius: 25px;
    border: 2px solid #FAA61A;
    margin: 20px;
   
  }
  @media screen and (max-width:500px) {
    .rcornersflight{
      margin-left:40px;
      margin-right:0px !important
    }
    .rcorners2{
      margin-left:40px;
      margin-right:0px !important
    }
  }