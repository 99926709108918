

.unique-packages-section {
    margin-top: -20px;
    padding: 20px;
    padding-left: 20px;
    background-color: #f9f9f9;
}
@media  (min-width: 736px) {
    .unique-packages-section {
        margin-top: -20px;
        padding: 20px;
        padding-left: 70px;
        background-color: #f9f9f9;
    }
  }

.unique-packages-container {
    max-width: 1200px;
    margin: 0 auto;
}

/* Row layout for the packages */
.unique-packages-row {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

/* Each package column */
.unique-package-col-lg-3, .unique-package-col-md-3, .unique-package-col-sm-12 {
    flex: 1;
    max-width: 25%;
    box-sizing: border-box;
    padding: 0 15px;
}

/* Styling for the package details */
.unique-package-detail {
    min-height: 200px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 1px 3px 4px 2px rgba(0, 0, 0, 0.2);
    padding: 25px 15px;
    position: relative;
    margin-bottom: 30px;
}

/* Package name styling */
.unique-package-detail .unique-package-name {
    position: absolute;
    left: 20px;
    top: 5px;
    color: #252525;
    font-size: 13px;
    padding: 1px 15px;
    border-radius: 15px;
    background: #eff8ff;
    border: 1px solid #ccc;
    font-weight: 500;
}

/* Hotel detail styling */
.unique-package-detail .unique-hotel-detail {
    padding: 0;
    margin: 0 0 15px 0;
    overflow: hidden;
    margin-top: 12px;
} 
.unique-image-holder  {
    float: left;
    padding: 0;
    width: 35px;
} 

.unique-package-detail .unique-hotel-detail .unique-image-holder figure {
    border-radius: 50%;

    overflow: hidden;
    padding: 3px;
}
.unique-image-holder img{
    width: 100%;
}


.unique-package-detail .unique-hotel-detail .unique-text-holder {
    overflow: hidden;
    padding: 0 0 0 10px;
}

.unique-package-detail .unique-hotel-detail .unique-text-holder strong {
    display: block;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #000;
    text-transform: uppercase;
}

.unique-package-detail .unique-hotel-detail .unique-text-holder span {
    display: block;
    color: #000;
    font-size: 12px;
    line-height: 18px;
    min-height: 36px;
}

/* Inclusions list styling */
.unique-package-detail .unique-inclusions-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.unique-package-detail .unique-inclusions-list li {
    display: inline-block;
    width: 40%;
    font-size: 15px;
    line-height: 24px;
    color: #252525;
    font-weight: 500;
  

}

/* Price box styling */
.unique-package-detail .unique-price-box {
    padding: 3px 0 10px 0;
}

.unique-package-detail .unique-price-box strong {
    display: block;
    font-size: 20px;
    line-height: 24px;
    color: #252525;
    font-weight: 500;
    margin: 0 0 5px 0;
}

.unique-price-box em {
    font-style: normal;
    font-size: 15px;
    color: #252525;
}

/* Button styling */
.unique-center {
    text-align: center;
    margin-top: 20px;
}

.unique-center p {
    margin: 0;
}

.unique-center button {
    color: white;
    background-color: #e5b622;
    margin: 8px;
    padding: 5px 15px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    font-weight: bold;
}
.sevpack-padding{
    padding-left: 20px;
}