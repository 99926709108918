.economy-packages {
    /* background: linear-gradient(135deg, #e8e8e8 0%, #f5f5f5 100%); */
    padding: 40px 20px;
    /* border: 2px solid #ccc; */
    /* border-radius: 15px; */
    background-image: linear-gradient(180deg, #000 20%, #000000 100%);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
@media (min-width: 991px) {

.vip-margin{
  margin: 53px;
}
}
@media (min-width: 991px) {

.outer-margin{
  margin: 70px;
}
}
@media (max-width: 991px) {
.vip-border{
  padding-top: 10%;
  padding-bottom: 10%;
  padding-left: 5%;
  padding-right: 5%;
  border: 5px solid #d6b864;

}
}
@media (min-width: 991px) {
.vip-border{
  border: 3px solid #d6b864;
}
}
.economy-heading {
  text-align: center;
  margin-bottom: 30px;
}

.economy-heading h2 {
  font-size: 2.5em;
  margin-bottom: 10px;
  font-weight: bold;
  color: #fff;
}

.economy-heading p {
  font-size: 1.2em;
  color: #fff;
}

.economy-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.economy-col {
  flex: 1 1 30%;
  max-width: 30%;
  padding: 10px;
  box-sizing: border-box;
}

.economy-feature-block {
  /* background: white; */
  padding: 20px;
  /* border-radius: 10px; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  text-align: center;
  /* border: 1px solid #ddd; */
  transition: transform 0.3s, box-shadow 0.3s;
}
.economy-feature-block:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.economy-feature-block span {
  font-size: 50px;
  color: #e5b622;
  margin-bottom: 10px;
  display: block;
}
.economy-feature-text h4 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #fff !important;
}

.economy-feature-text p {
  font-size: 1em;
  color: #fff !important;
}
@media (min-width:991px) {
  

.economy-description {
  margin-top: 30px;
  text-align: center;
  padding-left: 60px;
  padding-right: 60px;
}
}

@media (max-width:735px) {
  

  .economy-description {
    margin-top: 30px;
    text-align: center;
    
  }
  }
  

.economy-description p {
  /* font-size: 1.1em; */
  line-height: 1.5;
  color: #fff;
}

@media (max-width: 768px) {
  .economy-col {
    flex: 1 1 45%;
    max-width: 45%;
  }
}

@media (max-width: 576px) {
  .economy-col {
    flex: 1 1 100%;
    max-width: 100%;
  }
}


  